<template>
	<h1>Form Button</h1>
	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre v-highlightjs><code class="language-javascript">import FormButton from '@/components/FormButton'

export default {
	components: { FormButton },
	data() { return { waiting: false } },
	methods: {
		onClick() {
			this.waiting = true
			await someApiCall()
			this.waiting = false
		},
	},
}
</code></pre>
	</div>
	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<div class="buttons-wrap">
			<FormButton :level="1" @click="onClick(1)" :value="['Primary', 'Testing']" :wait="waiting1" />
			<FormButton :level="2" @click="onClick(2)" value="Secondary" :wait="waiting2" />
			<FormButton :level="3" @click="onClick(3)" value="Tertiary" :wait="waiting3" />
			<br />
			<br />
			<FormButton :level="1" @click="onClick(1)" value="Icon 1" icon="invalid" />
			<FormButton :level="2" @click="onClick(2)" value="Icon 2" icon="invalid" />
			<FormButton :level="3" @click="onClick(3)" value="Icon 3" icon="invalid" iconColor="#d00" />
			<br /><br />
			<FormButton :level="1" @click="onClick(1)" :small="true" value="Small 1" :wait="waiting1" />
			<FormButton :level="2" @click="onClick(2)" :small="true" value="Small 2" :wait="waiting2" />
			<FormButton :level="3" @click="onClick(3)" :small="true" value="Small 3" :wait="waiting3" />
		</div>
		<div class="buttons-wrap dark">
			<FormButton
				:level="1"
				:inverse="true"
				@click="onClick(1)"
				:value="['Primary', 'Testing']"
				:wait="waiting1"
			/>
			<FormButton :level="2" :inverse="true" @click="onClick(2)" value="Secondary" :wait="waiting2" />
			<FormButton :level="3" :inverse="true" @click="onClick(3)" value="Tertiary" :wait="waiting3" />
		</div>
	</div>
</template>

<script>
import FormButton from '@/components/FormButton'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocFormsButton',
	components: { FormButton },
	setup() {
		injectMetaData({
			title: 'Doc: Forms › Button',
		})
	},
	data() {
		return {
			waiting1: false,
			waiting2: false,
			waiting3: false,
			html: [
				'<FormButton',
				'	:level="1"						// 1/2/3 - primary/secondary/tertiary',
				'	@click="onClick"				// Click handler',
				'	value="Click me"				// Static value, default \'Submit\'',
				"	:value=\"['Test', 'Testing']\"	// Dynamic value, second value will be",
				'									// shown while button is in wait state',
				'	:small="true"					// Creates small button',
				'	:wait="waiting"					// Controls waite state',
				'	icon="invalid"					// Add icon',
				'	iconColor="#d00"				// Set custom icon color',
				'/>',
			].join('\n'),
		}
	},
	methods: {
		onClick(nr) {
			this[`waiting${nr}`] = true
			setTimeout(() => {
				this[`waiting${nr}`] = false
			}, 2000)
		},
	},
}
</script>

<style scoped lang="scss">
.buttons-wrap.dark {
	background: $black;
	padding: 0.3rem;
}
</style>
